import { LanguageResource, languageResourceBuilder, OneParamLanguageResourceTemplate } from "@ruter-as/web-components-and-tools";

interface ShoolTicketsLanguageResource {
  title: string;
  name: string;
  studentId: string;
  class: string;
  ticketCarrier: string;
  status: string;
  search: string;
  cardName: string;
  address: string;
  downloadActiveAndPendingAsCSV: OneParamLanguageResourceTemplate;
  all: string;
  cardNumber: string;
  zoneFrom: string;
  zoneTo: string;
  filter: string
  downloadFailed: string;
}

const schoolTicketsLanguageResource: LanguageResource<ShoolTicketsLanguageResource> = {
  nb: {
    title: "Skolebilletter",
    name: "Navn",
    class: "Klasse",
    status: "Status",
    studentId: "Elev-id",
    ticketCarrier: "Billettbærer",
    search: "Søk",
    cardName: "Kortnavn",
    address: "Adresse",
    downloadActiveAndPendingAsCSV: languageResourceBuilder.oneParamTemplate(`Last ned $p1$ aktive og ventende skolebilletter som CSV`),
    all: "Alle",
    cardNumber: "Reisekortnummer",
    zoneFrom: "Fra-sone",
    zoneTo: "Til-sone",
    filter: "Filter:",
    downloadFailed: "Klarte ikke å laste ned skolebilletter",
  },
  en: {
    title: "School-tickets",
    name: "Name",
    class: "Class",
    status: "Status",
    studentId: "Student-id",
    ticketCarrier: "Ticket carrier",
    search: "Search",
    cardName: "Card name",
    address: "Address",
    downloadActiveAndPendingAsCSV: languageResourceBuilder.oneParamTemplate(`Download $p1$ active and pending tickets as CSV`),
    all: "All",
    cardNumber: "Travel card number",
    zoneFrom: "From-zone",
    zoneTo: "To-zone",
    filter: "Filter:",
    downloadFailed: "Downloading school tickets failed",
  },
};

export default schoolTicketsLanguageResource;
