import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface SchoolTicketOrderLanguageResource {
  title: string;
  chooseMediaType: string;
  mobileTicket: string;
  travelcard: string;
  studentId: string;
  duplicateTicket: string;
  orderTicket: string;
  schoolClass: string;
  agreement: string;
  cardId: string;
  numberOfTravelCards: string;
  name: string;
  address: string;
  orderTicketSuccess: string;
  orderTicketFail: string;
}

const schoolTicketOrderLanguageResource: LanguageResource<SchoolTicketOrderLanguageResource> = {
  nb: {
    title: "Ny skolebillett",
    chooseMediaType: "Velg billettbærer",
    mobileTicket: "Mobil",
    travelcard: "Reisekort",
    studentId: "Elev-Id",
    duplicateTicket: "Denne eleven har allerede en skolebillett i aktuelt tidsrom.",
    orderTicket: "Bestill billett",
    schoolClass: "Klasse",
    agreement: "Avtalenummer",
    cardId: "Kort ID",
    numberOfTravelCards: "Antall reisekort",
    name: "Navn",
    address: "Adresse",
    orderTicketSuccess: "Billett sendt!",
    orderTicketFail: "Bestilling av billetten feilet!",
  },
  en: {
    title: "New school ticket",
    chooseMediaType: "Choose media type",
    mobileTicket: "Mobile ticket",
    studentId: "Student Id",
    travelcard: "Travel card",
    duplicateTicket: "This ticket already exists",
    orderTicket: "Order ticket",
    schoolClass: "Class",
    agreement: "Agreement number",
    cardId: "Card name",
    numberOfTravelCards: "Number of travel cards",
    name: "Name",
    address: "Address",
    orderTicketSuccess: "Ticket ordered successfully!",
    orderTicketFail: "Failed to order ticket!",
  },
};

export default schoolTicketOrderLanguageResource;
